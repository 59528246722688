function toFirstParagraph(indexValue) {
  let paragraph = null;

  if (indexValue && Array.isArray(indexValue)) {
    paragraph = indexValue[0];
  }

  return paragraph;
}
export { toFirstParagraph, };
