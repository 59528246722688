// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import Highlight from '../Highlight';

const hitStyles = ({ theme, isHighlighted, }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: '2rem 3rem',
  backgroundColor: isHighlighted ? theme.color('searchEngine', 'suggestionsHighlightedBg') : null,

  ':hover': {
    backgroundColor: theme.color('searchEngine', 'suggestionsHighlightedBg'),
  },
  ':focus': {
    backgroundColor: theme.color('searchEngine', 'suggestionsHighlightedBg'),
  },

  extend: [ theme.type(0), ],
});

type HitPropsType = {
  hit: Object,
  isHighlighted?: ?boolean,
  attribute: string,
};

function Hit(props: HitPropsType) {
  const { hit, isHighlighted, attribute, } = props;
  const hitClassName = useFela({ isHighlighted, }).css(hitStyles);
  return (
    <div className={hitClassName}>
      <Highlight hit={hit} attribute={attribute} highlightedColor="transparent" />
    </div>
  );
}

Hit.defaultProps = {
  isHighlighted: false,
};

export default Hit;
