// @flow
import * as React from 'react';
import { Index, Configure, connectStateResults, } from 'react-instantsearch-dom';
import CustomInfiniteHits from './CustomInfiniteHits';
import NoResults from './NoResults';
import useGetComponent from '../../../hooks/GetComponentContext/useGetComponent';
import { type TeaserDataMapperType, defaultTeaserDataMapperObject, } from './teaserDataMapper';
import { type ListExtraLinkType, } from '../../../flowTypes/ListExtraLinkType';
import EventTracker from '../../../utils/EventTracker';
import { clickedObjectIDsAfterSearchEvent, } from '../../../utils/algoliaInsightEvents';

import { type IndexConfiguration, } from '../AutoComplete/autocompleteTypes';

const StateResults = ({ isSearchStalled, children, }) => (isSearchStalled ? null : children);
const CustomStateResults = connectStateResults(StateResults);

const aligoliaListBiAction = (listId, view, biAction) => ({ index, data, }) => biAction({
  actionCode: 109,
  ArticleId: data.representedContent || data.contentId,
  ListId: listId,
  NoInList: index + 1,
  ViewName: view,
});

type AlgoliaListPropsType = {
  /**
   * List title
   */
  title?: string,
  extraLinks: ?(ListExtraLinkType[]),
  contentId: string,
  hasPagination: boolean,
  isLazyloadImages: boolean,
  /**
   * Name of list-view to use.
   */
  view: string,
  /**
   * filter query to pass to Algolia search
   * See docs on: https://www.algolia.com/doc/api-reference/api-parameters/filters/
   */
  filter: ?string,
  /**
   * An object that maps the search-index hit data into a TeaserData structure.
   * The sctucture of a mapper object is :
   * {
   *    <search-index-hit-key> : <TeaserData-field-key> |
   *                             {
   *                               key: <TeaserData-field>,
   *                               translator: (<search-index-hit-value>) =>  <TeaserData-field-value>
   *                             }
   *    ...
   * }
   */
  hitMapper: TeaserDataMapperType,
  /**
   * Algolia index name to search in
   */
  indexName: string,
  /**
   * Number of items per result page
   */
  itemsPerPage: number,
  /**
   * The query string that was used by Algolia to perform the search.
   */
  query: ?string,
};

function AlgoliaList({
  hitMapper,
  filter,
  indexName,
  itemsPerPage,
  query,
  ...list
}: AlgoliaListPropsType) {
  const getListView = useGetComponent();
  const ListInner = getListView(list.view);

  return (
    <EventTracker>
      {({ biAction, gaAction, HtzReactGA, }) => {
        // statistics click events aggregator
        const clickActions = [
          clickedObjectIDsAfterSearchEvent,
          aligoliaListBiAction(list.contentId, list.view, biAction),
        ];

        return (
          <Index indexName={indexName}>
            <Configure
              hitsPerPage={itemsPerPage}
              analytics
              clickAnalytics
              filters={filter}
              attributesToRetrieve={[
                'articleType',
                'authors',
                'image',
                'modifiedDate',
                'publishDate',
                'subTitle',
                'title',
                'updateDate',
                'url',
              ]}
            />
            <CustomStateResults>
              <CustomInfiniteHits hitMapper={hitMapper}>
                {({ items, searchResults, }) => (items && items.length > 0 ? (
                  <ListInner
                    list={{ ...list, items, }}
                    totalHits={searchResults.nbHits}
                    query={query}
                    biAction={clickActions}
                  />
                ) : (
                  <NoResults />
                ))
                }
              </CustomInfiniteHits>
            </CustomStateResults>
          </Index>
        );
      }}
    </EventTracker>
  );
}

AlgoliaList.defaultProps = {
  hitMapper: defaultTeaserDataMapperObject,
  title: null,
  extraLinks: null,
  hasPagination: true,
  isLazyloadImages: false,
  itemsPerPage: 5,
  filter: undefined,
};

export default AlgoliaList;
