// @flow
/* global window */

type ClickedObjectIDsAfterSearch = {
  userToken?: string,
  eventName?: string,
  index: string,
  queryID: string,
  objectIDs: Array<string>,
  positions: Array<number>,
};

type CallbackType = {
  index: number,
  data: Object,
  actionCode?: ?number,
};

export default function algoliaInsightEvent(method: string, data: Object) {
  if (window && window.aa) {
    window.aa(method, data);
  }
}

export function clickedObjectIDsAfterSearchEvent({ data, }: CallbackType) {
  const insightData: ClickedObjectIDsAfterSearch = {
    eventName: 'Search result click',
    index: data.index,
    queryID: data.queryID,
    objectIDs: [ data.objectID, ],
    positions: [ data.position, ],
  };
  return algoliaInsightEvent('clickedObjectIDsAfterSearch', insightData);
}
