// @flow

import * as React from 'react';
import { Highlight, connectAutoComplete, } from 'react-instantsearch-dom';
import { useFela, } from 'react-fela';
import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';
import AutoSuggest from 'react-autosuggest';
import { type SuggestionSelected, } from './autocompleteTypes';


type PropsType = {
  id: ?string,
  name: string,
  multiSection: boolean,
  hits: Array<Object>,
  defaultRefinement: ?string,
  currentRefinement: string,
  refine: ?string => void,
  onSuggestionSelected: ?(event: SyntheticEvent<HTMLElement>, data: SuggestionSelected) => void,
  getSuggestionRenderer: ?(Object) => React.ComponentType<any>,
  getSectionTitleRenderer: ?(Object) => React.ComponentType<any>,
  getSuggestionValue: Object => string,
  inputRef: ?{ current: ?HTMLInputElement, },
  placeholder: ?string,

  containerMiscStyles: ?StyleProps,
  inputMiscStyles: ?StyleProps,
  suggestionsContainerMiscStyles: ?StyleProps,
};

// $FlowFixMe
const ReferableInput = React.forwardRef((inputProps, ref) => <input {...inputProps} ref={ref} />);

function containerRule({ theme, containerMiscStyles, }) {
  return {
    extend: [
      ...(containerMiscStyles ? parseStyleProps(containerMiscStyles, theme.mq, theme.type) : []),
    ],
  };
}

function inputRule({ theme, inputMiscStyles, }) {
  return {
    extend: [ ...(inputMiscStyles ? parseStyleProps(inputMiscStyles, theme.mq, theme.type) : []), ],
  };
}

function suggestionsContainerRule({ theme, suggestionsContainerMiscStyles, }) {
  return {
    extend: [
      ...(suggestionsContainerMiscStyles
        ? parseStyleProps(suggestionsContainerMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function renderInputComponent(inputProps) {
  const { inputRef, ...props } = inputProps;
  return <ReferableInput {...props} ref={inputRef} />;
}

function AutoComplete({
  id,
  name,
  multiSection,
  hits,
  defaultRefinement,
  currentRefinement,
  refine,
  onSuggestionSelected,
  getSuggestionRenderer,
  getSectionTitleRenderer,
  getSuggestionValue,
  inputRef,
  placeholder,
  containerMiscStyles,
  inputMiscStyles,
  suggestionsContainerMiscStyles,
}: PropsType) {
  const [ value, setValue, ] = React.useState(currentRefinement);
  const { css, } = useFela({
    containerMiscStyles,
    inputMiscStyles,
    suggestionsContainerMiscStyles,
  });

  React.useEffect(() => {
    if (defaultRefinement) {
      setValue(defaultRefinement);
    }
  }, [ defaultRefinement, ]);

  const containerClassNames = css(containerRule);
  const inputClassNames = css(inputRule);
  const suggestionsContainerClassNames = css(suggestionsContainerRule);

  const onChange = (evt, { newValue, }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value, }) => {
    refine(value);
  };

  const onSuggestionsClearRequested = () => {
    refine();
  };

  const renderSuggestion = (hit, { isHighlighted, }) => {
    const Suggestion = getSuggestionRenderer && getSuggestionRenderer(hit);
    return Suggestion ? <Suggestion hit={hit} isHighlighted={isHighlighted} /> : null;
  };

  const renderSectionTitle = section => {
    const SectionTitle = getSectionTitleRenderer && section && section.hits && section.hits.length > 0 ? getSectionTitleRenderer(section) : null;
    return SectionTitle ? <SectionTitle section={section} /> : null;
  };

  const getSectionSuggestions = section => section.hits.map(hit => ({ ...hit, indexName: section.index, }));

  const inputProps = {
    placeholder,
    onChange,
    value,
    name,
    inputRef,
  };

  return (
    <AutoSuggest
      id={id}
      suggestions={hits}
      multiSection={multiSection}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      renderInputComponent={renderInputComponent}
      theme={{
        container: containerClassNames,
        input: inputClassNames,
        suggestionsContainerOpen: suggestionsContainerClassNames,
      }}
    />
  );
}

AutoComplete.defaultProps = {
  id: null,
  onSuggestionSelected: null,
  getSuggestionRenderer: hit => Highlight,
  getSectionTitleRenderer: section => null,
  inputRef: null,
  placeholder: null,
  defaultRefinement: null,
};

export default connectAutoComplete(AutoComplete);
