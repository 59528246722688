// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import Highlight from '../Highlight';
import teaserDataMapper, { defaultTeaserDataMapperObject, } from '../AlgoliaList/teaserDataMapper';
import Teaser from '../../Teaser/NewTeaser';
import TeaserMedia from '../../TeaserMedia/NewTeaserMedia';
import Image from '../../Image/Image';
import TeaserContent from '../../TeaserContent/NewTeaserContent';
import HtzLink from '../../HtzLink/HtzLink';
import H from '../../AutoLevels/H';

import type { TeaserDataType, } from '../../../flowTypes/TeaserDataType';

type ArticleHitPropsType = {
  hit: Object,
  isHighlighted?: boolean,
};

const hitStyles = ({ theme, isHighlighted, }) => ({
  backgroundColor: isHighlighted ? theme.color('primary') : null,
  paddingTop: '1rem',
  paddingBottom: '1rem',
  paddingInlineStart: '3rem',

  ':hover': {
    backgroundColor: theme.color('primary'),
  },
  ':focus': {
    backgroundColor: theme.color('primary'),
  },
  extend: [ theme.type(0), ],
});

function ArticleHit(props: ArticleHitPropsType) {
  const { hit, isHighlighted, } = props;
  const { css, } = useFela({ isHighlighted, });
  const hitClassName = css(hitStyles);
  const hitAsTeaser: TeaserDataType = React.useMemo(
    () => teaserDataMapper(hit, defaultTeaserDataMapperObject),
    [ hit, ]
  );
  return (
    <div className={hitClassName}>
      <Teaser
        backgroundColor="transparent"
        color="white"
        data={hitAsTeaser}
        areasTemplate="'media content'"
        gridGap="2rem"
        colTemplate="10rem 1fr"
      >
        <TeaserMedia data={hitAsTeaser}>
          <Image
            imgOptions={{ transforms: { width: 70, height: 60, aspect: 'regular', }, }}
            image={hitAsTeaser.image}
          />
        </TeaserMedia>
        <TeaserContent tagName="div">
          <HtzLink href={hitAsTeaser.path}>
            <H>
              <Highlight hit={hit} attribute="title" highlightedColor="transparent" />
            </H>
          </HtzLink>
        </TeaserContent>
      </Teaser>
    </div>
  );
}

ArticleHit.defaultProps = {
  isHighlighted: false,
};

export default ArticleHit;
