/* eslint-disable react/no-danger */
/* global window */

/* eslint-disable */
function loadAlgoliaInsightsScript(appId, searchApiKey) {
  !(function(e, a, t, n, s, i, c) {
    (e.AlgoliaAnalyticsObject = s),
      (e.aa =
        e.aa ||
        function() {
          (e.aa.queue = e.aa.queue || []).push(arguments);
        }),
      (i = a.createElement(t)),
      (c = a.getElementsByTagName(t)[0]),
      (i.async = 1),
      (i.src = 'https://cdn.jsdelivr.net/npm/search-insights@1.1.1'),
      c.parentNode.insertBefore(i, c);
  })(window, document, 'script', 0, 'aa');

  // Initialize library
  aa('init', {
    appId,
    apiKey: searchApiKey,
  });
}
/* eslint-enable */

function AlgoliaInsightsScript({ appId, searchApiKey, }) {
  if (typeof window === 'undefined') {
    return null;
  }
  if (!window.aa) {
    loadAlgoliaInsightsScript(appId, searchApiKey);
  }
  return null;
}

export default AlgoliaInsightsScript;
