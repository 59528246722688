import * as React from 'react';
import config from 'config';
import useQueryParam from '../../../hooks/useQueryParam';
import AlgoliaInstantSearch from './AlgoliaInstantSearch';

export default function AlgoliaInstantSearchUrl(props) {
  const [ queryParam, setQueryParam, ] = useQueryParam('q');


  return (
    <AlgoliaInstantSearch
      {...{
        appId: config.get('algoliaAppId'),
        searchApiKey: config.get('algoliaAPIKey'),
        ...props, }}
      onQueryChange={setQueryParam}
      initialQuery={queryParam}
    />
  );
}
