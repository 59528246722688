function toAuthors(indexValue) {
  let authors = null;

  if (indexValue && Array.isArray(indexValue)) {
    authors = indexValue.map(author => toAuthor(author));
  }

  return authors;
}

function toFirstAuthor(indexValue) {
  let firstAuthor = null;

  if (indexValue && Array.isArray(indexValue) && indexValue.length > 0) {
    firstAuthor = toAuthor(indexValue[0]);
  }

  return firstAuthor;
}

function toAuthor(indexValue) {
  let author = null;

  if (indexValue) {
    author = {
      inputTemplate: 'com.tm.Author',
      contentName: indexValue.name,
      contentId: indexValue.contentId,
    };
  }

  return author;
}

export { toAuthors, toFirstAuthor, };
