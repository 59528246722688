// @flow

import * as React from 'react';
import { Configure, InstantSearch, Index, } from 'react-instantsearch-dom';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import AutoComplete from './AutoComplete';
import AlgoliaInsightsScript from '../AlgoliaInsightsScript';
import customalgoliasearch from '../../../utils/algoliaSearchClient';
import DefaultHit from './DefaultHit';

import { type IndexConfiguration, type SuggestionSelected, } from './autocompleteTypes';

type PropsType = {
  id: ?string,
  name: string,
  appId: string,
  apiKey: string,
  indexConfiguration: IndexConfiguration | Array<IndexConfiguration>,
  refinementMinLength: ?number,
  defaultRefinement: ?string,
  onSuggestionSelected: ?(event: SyntheticEvent<HTMLElement>, data: SuggestionSelected) => void,
  inputRef: ?{ current: ?HTMLInputElement, },
  placeholder: ?string,

  containerMiscStyles: ?StyleProps,
  inputMiscStyles: ?StyleProps,
  suggestionsContainerMiscStyles: ?StyleProps,
};

type EmptyRootType = {
  children: React.ChildrenArray<React.Node> | React.Node,
  props: Object,
}

function EmptyRoot({ children, ...props }: EmptyRootType) {
  return (<React.Fragment {...props}>{children}</React.Fragment>);
}

function MultiSectionAutoComplete({
  id,
  name,
  appId,
  apiKey,
  indexConfiguration,
  refinementMinLength,
  defaultRefinement,
  onSuggestionSelected,
  inputRef,
  placeholder,
  containerMiscStyles,
  inputMiscStyles,
  suggestionsContainerMiscStyles,
}: PropsType) {
  const configurations = Array.isArray(indexConfiguration)
    ? indexConfiguration
    : [ indexConfiguration, ];

  const searchClient = customalgoliasearch({
    applicationId: appId,
    apiKey,
    customOptions: { minQueryLength: refinementMinLength || 0, },
  });

  /**
   * Gets the component for rendering the suggetion.
   * @param {Object} hit the suggestion object
   */
  const getSuggestionRenderer = hit => {
    const indexConfig = configurations.length === 1
      ? configurations[0]
      : configurations.find(config => config.name === hit.indexName);
    let itemRender = DefaultHit;
    if (indexConfig !== null && indexConfig.SuggestionComponent) {
      itemRender = indexConfig.SuggestionComponent;
    }

    return itemRender;
  };

  /**
   * Extract the value to display of the suggestion
   * @param {string} hit the suggestion object
   */
  const getSuggestionValue = hit => {
    let value = hit;
    const indexConfig = configurations.length === 1
      ? configurations[0]
      : configurations.find(config => config.name === hit.indexName);
    if (indexConfig != null) {
      value = hit[indexConfig.valueProperty];
    }
    return value;
  };

  /**
   * Gets the component for rendering the section title.
   * @param {Object} section
   */
  const getSectionTitleRenderer = section => {
    const indexConfig = configurations.find(config => config.name === section.index);
    let sectionTitleRenderer = null;
    if (indexConfig !== null && indexConfig.SectionTitleComponent) {
      sectionTitleRenderer = indexConfig.SectionTitleComponent;
    }

    return sectionTitleRenderer;
  };

  return (
    <InstantSearch
      indexName={configurations[0].name}
      searchClient={searchClient}
      root={{ Root: EmptyRoot, }}
    >
      <AlgoliaInsightsScript appId={appId} searchApiKey={apiKey} />
      {configurations.map(conf => (conf.config ? (
        <Index key={conf.id || conf.name} indexName={conf.name} indexId={conf.id} root={{ Root: EmptyRoot, }}>
          <Configure {...conf.config} />
        </Index>
      ) : (
        <Index key={conf.id || conf.name} indexName={conf.name} indexId={conf.id} root={{ Root: EmptyRoot, }} />
      ))
      )}
      <AutoComplete
        id={id}
        name={name}
        multiSection={configurations.length > 1}
        getSuggestionRenderer={getSuggestionRenderer}
        getSectionTitleRenderer={getSectionTitleRenderer}
        getSuggestionValue={getSuggestionValue}
        defaultRefinement={defaultRefinement}
        onSuggestionSelected={onSuggestionSelected}
        inputRef={inputRef}
        placeholder={placeholder}
        containerMiscStyles={containerMiscStyles}
        inputMiscStyles={inputMiscStyles}
        suggestionsContainerMiscStyles={suggestionsContainerMiscStyles}
      />
    </InstantSearch>
  );
}

MultiSectionAutoComplete.defaultProps = {
  refinementMinLength: 0,
  defaultRefinement: null,
  placeholder: null,
  id: null,
};

export default MultiSectionAutoComplete;
