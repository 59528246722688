/* global window */
let hostname = null;
let subdomain = null;
// A hostname considered as proxy, if the sub-domain contains the word 'proxy' or dash(-)
let isProxy = false;

if (typeof window !== 'undefined') {
  const domainRegex = /([a-z]+\.)(.+$)/gi;
  const subdomainRegex = /^[a-z0-9-]+(?=\.)/gi;
  const proxyRegex = /(proxy)|-/gi;
  const matches = domainRegex.exec(window.location.hostname);
  const matchesSub = subdomainRegex.exec(window.location.hostname);

  if (matches && matches.length >= 3) {
    hostname = matches[2];
  }

  if (matchesSub) {
    subdomain = matchesSub[0];
    isProxy = proxyRegex.test(subdomain);
  }
}

function toPath(indexValue) {
  const pathRegEx = /(?:\/\/.*?)(\/.*$)/gi;
  let path = indexValue;

  if (indexValue && typeof window !== 'undefined') {
    if (isURLFromDomain(path, hostname) || isProxy) {
      const matches = pathRegEx.exec(path);
      if (matches && matches.length >= 2) {
        path = matches[1];
      }
    }
  }

  return path;
}

function isURLFromDomain(url, domain) {
  return domain !== null && url.includes(domain);
}

export { toPath, };
