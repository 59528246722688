// @flow
import * as React from 'react';
import { type HitProps, } from './autocompleteTypes';

function DefaultHit({ hit, isHighlighted, }: HitProps) {
  return <div>{isHighlighted ? <strong>{hit}</strong> : hit}</div>;
}

DefaultHit.defaultProps = { isHighlighted: false, };

export default DefaultHit;
