// @flow
/* global window */

import * as React from 'react';
import { useFela, } from 'react-fela';
import { InstantSearch, connectSearchBox, } from 'react-instantsearch-dom';
import { parseStyleProps, type StyleProps, } from '@haaretz/htz-css-tools';
import customalgoliasearch from '../../../utils/algoliaSearchClient';
import useGetComponent from '../../../hooks/GetComponentContext/useGetComponent';
import AlgoliaInsightsScript from '../AlgoliaInsightsScript';
import ArticleSearchBox from './ArticlesSearchBox';
import RecipesSearchBox from './RecipesSearchBox';
import LayoutRow from '../../PageLayout/LayoutRow';
import LayoutContainer from '../../PageLayout/LayoutContainer';
import type { ColorPropType, } from '../../../flowTypes/ColorPropType';

type AlgoliaInstantSearchPropsType = {
  /**
   * Algolia App id
   */
  appId: string,
  /**
   * Algolia search api key
   */
  searchApiKey: string,
  /**
   * Search box type.
   * artilce - search any kind of article
   * recipes - shows a multi-section search of autocomplete and recipes articles
   */
  searchType: 'articles' | 'recipes',
  /**
   * Render function for children. the function accepts the users query-string
   */
  children: (query: ?string) => React.ChildrenArray<React.Node> | React.Node,
  /**
   * JSON Array of PAPI elements.
   */
  elements: ?Array<Object>,
  miscStyles: ?StyleProps,
  searchWrapperMiscStyles: ?StyleProps,
  /**
   * Query to initialize the SearchBox with
   */
  initialQuery: ?string,
  /**
   * on query change handler
   */
  onQueryChange: ?(string) => void,
  /**
   * Background color for search box
   */
  searchboxBgc: ?ColorPropType,
};

const VirtualSearchBox = connectSearchBox(() => null);

function searchWrapperStyle({ theme, isSticky, searchboxBgc, searchWrapperMiscStyles, }) {
  return {
    backgroundColor: searchboxBgc ? theme.color(searchboxBgc[0], searchboxBgc[1]) : null,
    extend: [
      (isSticky
        ? theme.mq(
          { until: 's', },
          {
            position: 'sticky',
            top: 0,
            zIndex: theme.getZIndex('stickyListViewHeader'),
          }
        )
        : []),
      ...(searchWrapperMiscStyles
        ? parseStyleProps(searchWrapperMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function AlgoliaInstantSearch({
  appId,
  searchApiKey: apiKey,
  searchType,
  children,
  elements,
  miscStyles,
  searchWrapperMiscStyles,
  searchboxBgc,
  initialQuery,
  onQueryChange,
}: AlgoliaInstantSearchPropsType) {
  const { css, } = useFela({ isSticky: true, searchboxBgc, searchWrapperMiscStyles, });
  const [ currentQuery, setQuery, ] = React.useState(initialQuery);

  // Handle default-query prop change
  React.useEffect(() => {
    setQuery(initialQuery);
  }, [ initialQuery, ]);

  const SelectedSearchBox = searchType === 'articles' ? ArticleSearchBox : RecipesSearchBox;

  const searchClient = customalgoliasearch({
    applicationId: appId,
    apiKey,
    customOptions: { minQueryLength: 2, },
  });

  const getComponent = useGetComponent();

  const searchWrapperClasses = css(searchWrapperStyle);

  return (
    <LayoutRow namedBgc="transparent" miscStyles={miscStyles}>
      <LayoutContainer namedBgc="transparent">
        <div className={searchWrapperClasses}>
          <SelectedSearchBox
            applicationId={appId}
            apiKey={apiKey}
            defaultQuery={initialQuery}
            onSuggestionSelected={query => {
              onQueryChange && onQueryChange(query);
              setQuery(query);
            }}
          />
        </div>

        <InstantSearch searchClient={searchClient} indexName="articles">
          <AlgoliaInsightsScript appId={appId} searchApiKey={apiKey} />
          <VirtualSearchBox defaultRefinement={currentQuery} />
          {children && children(currentQuery)}
          {elements
            ? elements.map(element => {
              const Element = getComponent(element.inputTemplate);

              return <Element key={element.contentId} query={currentQuery} {...element} />;
            })
            : null}
        </InstantSearch>
      </LayoutContainer>
    </LayoutRow>
  );
}

AlgoliaInstantSearch.defaultProps = {
  elements: null,
  children: null,
  initialQuery: null,
  onQueryChange: null,
  miscStyles: null,
  searchWrapperMiscStyles: null,
};

export default AlgoliaInstantSearch;
