function unixTimeToMillis(unixTime) {
  let time = null;
  if (unixTime && Number.isInteger(unixTime)) {
    time = unixTime * 1000;
  }

  return time;
}

export { unixTimeToMillis, };
