// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import { connectInfiniteHits, connectStateResults, } from 'react-instantsearch-dom';

import hitMapper, { type TeaserDataMapperType, } from './teaserDataMapper';
import ClickArea from '../../ClickArea/ClickArea';
import LayoutRow from '../../PageLayout/LayoutRow';
import LayoutContainer from '../../PageLayout/LayoutContainer';

type InfiniteHitsPropsType = {
  hits: Array<Object>,
  searchResults: Object,
  hasMore: boolean,
  refine: () => void,
  hitMapper: TeaserDataMapperType,
  children: ({ items: Array<Object>, searchResults: Object, }) => React.Node,
};

function InfiniteHits({
  hits,
  searchResults,
  hasMore,
  refine,
  hitMapper: hitMapperObject,
  children,
  ...props
}: InfiniteHitsPropsType) {
  const { theme, } = useFela();

  // Convert index-hit-data into teaser-data compatible data.
  const items = hits.map(hit => {
    let item = hitMapper(hit, hitMapperObject);
    item = {
      ...item,
      objectID: hit.objectID,
      index: searchResults.index,
      // eslint-disable-next-line no-underscore-dangle
      position: hit.__position,
      // eslint-disable-next-line no-underscore-dangle
      queryID: hit.__queryID,
    };
    return item;
  });

  return (
    <React.Fragment>
      {children({ items, searchResults, })}
      {hasMore ? (
        <LayoutRow namedBgc="transparent">
          <LayoutContainer
            bgc="transparent"
            miscStyles={{ paddingBlockStart: '4rem', display: 'flex', justifyContent: 'center', }}
          >
            <ClickArea
              miscStyles={{
                height: '6rem',
                width: '18rem',
                color: theme.color('button', 'algoliaText'),
                fontWeight: 700,
                border: [ '1px', 1, 'solid', ],
              }}
              onClick={refine}
            >
              {theme.headerSearchI18n.loadMore}
            </ClickArea>
          </LayoutContainer>
        </LayoutRow>
      ) : null}
    </React.Fragment>
  );
}

const CustomInfiniteHits = connectInfiniteHits(connectStateResults(InfiniteHits));
export default CustomInfiniteHits;
