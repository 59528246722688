// @flow
import React from 'react';
import Hit from './AutoComplete/Hit';
import ArticleHit from './AutoComplete/ArticleHit';
import AuthorHit from './AutoComplete/AuthorHit';
import AutoCompleteSectionTitle from './AutoComplete/SectionTitle';
import AutoCompleteSectionDivider from './AutoComplete/SectionDivider';

import { type HitProps, } from './AutoComplete/autocompleteTypes';

const recipesConfiguration = [
  {
    name: 'recipes_autocomplete',
    SuggestionComponent: ({ hit, isHighlighted, }: HitProps) => (
      <Hit attribute="query" hit={hit} isHighlighted={isHighlighted} />
    ),
    valueProperty: 'query',
    config: {
      hitsPerPage: 2,
      analytics: true,
      clickAnalytics: true,
      attributesToRetrieve: [ 'query', ],
      attributesToHighlight: [ 'query', ],
    },
  },
  {
    name: 'recipe_articles',
    SuggestionComponent: ArticleHit,
    SectionTitleComponent: () => <AutoCompleteSectionTitle title="מתכונים מומלצים" />,
    valueProperty: 'title',
    config: {
      hitsPerPage: 3,
      analytics: true,
      clickAnalytics: true,
      attributesToRetrieve: [ 'title', 'image', 'url', 'articleType', ],
      attributesToHighlight: [ 'title', ],
      filters: 'articleType:recipeArticle OR tags.name:מתכונים OR tags.name:מתכון',
    },
  },
];

const articlesConfiguration = [
  {
    name: 'authors',
    SuggestionComponent: AuthorHit,
    SectionTitleComponent: null,
    valueProperty: 'name',
    config: {
      hitsPerPage: 1,
      analytics: true,
      clickAnalytics: true,
      attributesToRetrieve: [ 'name', 'url', 'image', ],
      attributesToHighlight: [ 'name', ],
    },
  },
  {
    name: 'autocomplete',
    SuggestionComponent: ({ hit, isHighlighted, }: HitProps) => (
      <Hit attribute="query" hit={hit} isHighlighted={isHighlighted} />
    ),
    SectionTitleComponent: () => <AutoCompleteSectionDivider />,
    valueProperty: 'query',
    config: {
      hitsPerPage: 4,
      analytics: true,
      clickAnalytics: true,
      attributesToRetrieve: [ 'query', ],
      attributesToHighlight: [ 'query', ],
    },
  },
];

export { articlesConfiguration, recipesConfiguration, };
