// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

const styles = ({ theme, }) => ({
  display: 'flex',
  fontWeight: 700,
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '5rem',
  color: theme.color('neutral', '-2'),
  ...theme.type(3),
});

const sadfaceStyles = ({ theme, }) => ({
  paddingTop: '2rem',
  fontWeight: 400,
  ...theme.type(5),
});

function NoResults() {
  const { css, theme, } = useFela();
  const className = css(styles);
  const sadfaceClassName = css(sadfaceStyles);

  return (
    <div className={className}>
      <div>{theme.headerSearchI18n.noResultsText}</div>
      <div className={sadfaceClassName}>&#9785;</div>
    </div>
  );
}

export default NoResults;
