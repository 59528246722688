// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { borderTop, } from '@haaretz/htz-css-tools';

const dividerStyles = ({ theme, }) => ({
  paddingInlineStart: '3rem',
  paddingInlineEnd: '3rem',
  marginInlineStart: '3rem',
  marginInlineEnd: '3rem',
  extend: [
    borderTop('1px', 1, 'solid', theme.color('primary', '-6')),
  ],
});

function AutoCompleteSectionDivider() {
  const { css, } = useFela();
  return (
    <div className={css(dividerStyles)} />
  );
}

export default AutoCompleteSectionDivider;
