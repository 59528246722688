function toRelatedArticles(indexValue) {
  let relatedArticles = null;

  if (indexValue && Array.isArray(indexValue)) {
    relatedArticles = indexValue.map(article => ({
      title: article.name,
      contentId: article.contentId,
      path: article.url,
    }));
  }

  return relatedArticles;
}

export { toRelatedArticles, };
