// @flow
/* global window */

import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';
import ClickArea, { type SizeType, } from '../ClickArea/ClickArea';
import IconSearch from '../Icon/icons/IconSearch';
import MultiSectionAutoComplete from '../Algolia/AutoComplete/MultiSectionAutoComplete';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import {
  type SuggestionSelected,
  type IndexConfiguration,
} from '../Algolia/AutoComplete/autocompleteTypes';
import EventTracker from '../../utils/EventTracker';

type SearchBoxPropsType = {
  id: ?string,
  name: string,
  defaultQuery: ?string,
  action: string,
  indexAppId: string,
  indexSearchApiKey: string,
  indexConfiguration: IndexConfiguration | Array<IndexConfiguration>,
  searchInputRef: ?{ current: ?HTMLInputElement, },
  inputPlaceholder: ?string,
  localStorageHistoryKey: ?string,

  submitOnSelect: ?boolean,
  onSubmit: ?(event: SyntheticEvent<HTMLElement>, query: ?string) => void,
  onSuggestionSelected: ?(event: SyntheticEvent<HTMLElement>, data: SuggestionSelected) => void,
  miscStyles: ?StyleProps,
  autocompleteContainerMiscStyles: ?StyleProps,
  inputMiscStyles: ?StyleProps,
  suggestionsContainerMiscStyles: ?StyleProps,
  buttonMiscStyles: ?StyleProps,
  buttonSize: SizeType,
  queryMinLength: number,
};

const addQueryToHistory = (query, localStorageHistoryKey) => {
  if (window.localStorage) {
    try {
      let searchHistoy = window.localStorage.getItem(localStorageHistoryKey)
        ? JSON.parse(window.localStorage.getItem(localStorageHistoryKey))
        : [];
      if (Array.isArray(searchHistoy)) {
        if (searchHistoy.includes(query)) {
          searchHistoy = searchHistoy.filter(item => item !== query);
        }
        searchHistoy.unshift(query);
        window.localStorage.setItem(localStorageHistoryKey, JSON.stringify(searchHistoy));
      }
    }
    catch (error) {
      console.error(error);
    }
  }
};

const formStyle = ({ theme, miscStyles, }) => ({
  display: 'flex',
  position: 'relative',
  extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
});

function SearchBox(props: SearchBoxPropsType) {
  const { css, theme, } = useFela({ miscStyles: props.miscStyles, });
  const localRef: { current: ?HTMLInputElement, } = React.useRef(null);
  const inputRef = props.searchInputRef || localRef;
  const formRef: { current: HTMLFormElement | null, } = React.useRef(null);

  const getInputValue = () => {
    const input = inputRef.current;
    const value = input ? input.value : null;

    return value;
  };

  const onSubmit = ({ event, biAction, }) => {
    biAction && biAction({
      actionCode: 144,
      feature: 'Algolia Search Field',
      featureType: 'Content',
    });

    const inputValue = getInputValue();

    if (props.localStorageHistoryKey) {
      addQueryToHistory(inputValue, props.localStorageHistoryKey);
    }
    if (props.onSubmit) {
      props.onSubmit(event, inputValue);
    }
  };

  const onSuggestionSelected = ({ event, data, biAction, }) => {
    if (props.onSuggestionSelected) {
      props.onSuggestionSelected(event, data);
    }

    if (props.submitOnSelect) {
      window.setTimeout(() => {
        biAction && biAction({
          actionCode: 144,
          feature: 'Algolia Search Field',
          featureType: 'Content',
        });

        formRef.current && formRef.current.submit();
      }, 150);
    }
  };

  const formClassName = css(formStyle);

  return (
    <EventTracker>
      {({ biAction, }) => (
        <form className={formClassName} ref={formRef} onSubmit={event => onSubmit({ event, biAction, })} action={props.action}>
          <MultiSectionAutoComplete
            id={props.id}
            name={props.name}
            appId={props.indexAppId}
            apiKey={props.indexSearchApiKey}
            refinementMinLength={props.queryMinLength}
            defaultRefinement={props.defaultQuery}
            onSuggestionSelected={(event, data) => onSuggestionSelected({ event, data, biAction, })}
            indexConfiguration={props.indexConfiguration}
            inputRef={inputRef}
            placeholder={props.inputPlaceholder}
            containerMiscStyles={props.autocompleteContainerMiscStyles}
            inputMiscStyles={props.inputMiscStyles}
            suggestionsContainerMiscStyles={props.suggestionsContainerMiscStyles}
          />
          <ClickArea miscStyles={props.buttonMiscStyles} isSubmit size={props.buttonSize}>
            <IconSearch size={props.buttonSize} aria-hidden />
            <VisuallyHidden>{theme.headerSearchI18n.a11yTexts.execSearch}</VisuallyHidden>
          </ClickArea>
        </form>
      )}
    </EventTracker>
  );
}

SearchBox.defaultProps = {
  id: null,
  defaultQuery: null,
  action: '',
  searchInputRef: null,
  inputPlaceholder: null,
  submitOnSelect: false,
  onSubmit: () => {},
  onSuggestionSelected: () => {},
  miscStyles: null,
  autocompleteContainerMiscStyles: null,
  inputMiscStyles: null,
  suggestionsContainerMiscStyles: null,
  buttonMiscStyles: null,
  buttonSize: 4,
  localStorageHistoryKey: null,
  queryMinLength: 0,
};
// $FlowFixMe
export default React.forwardRef((props: SearchBoxPropsType, ref) => (
  <SearchBox {...props} searchInputRef={ref} />
));
