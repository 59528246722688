// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import SearchBox from '../../Search/SearchBox';
import { articlesConfiguration, } from '../searchBoxConfigs';
import { type SuggestionSelected, } from '../AutoComplete/autocompleteTypes';

type PropsType = {
  applicationId: string,
  apiKey: string,
  onSuggestionSelected: string => void,
  defaultQuery: ?string,
};

const formMiscStyles = ({ theme, }) => ({
  margin: [ { until: 's', value: '0 2rem', }, { from: 's', value: '0 4rem', }, ],
  backgroundColor: 'transparent',
  borderBottom: [ '2px', 1, 'solid', theme.color('searchEngine', 'resultsFg'), ],
  marginBlockStart: 'auto',
});

const autocompleteContainerMiscStyles = ({ theme, }) => ({
  flexGrow: 1,
});

const inputMiscStyles = ({ theme, }) => ({
  backgroundColor: 'transparent',
  color: theme.color('neutral', '-3'),
  width: '100%',
  outline: 'none',
  type: [ { until: 'l', value: 2, }, { from: 'l', value: 5, }, ],
  fontWeight: theme.searchEngineStyle.resultsInputFontWeight,
  '::placeholder': {
    color: theme.color('neutral', '-3'),
    opacity: 0.6,
    fontWeight: theme.searchEngineStyle.placeholderFontWeight,
  },
});

const suggestionsContainerMiscStyles = ({ theme, }) => ({
  color: theme.color('searchEngine', 'searchFg'),
  position: 'absolute',
  top: 'calc(100% + 2px)',
  width: '100%',
  backgroundColor: theme.color('searchEngine', 'secondarySuggestionsBg'),
  zIndex: theme.getZIndex('modal'),
  borderLeft: [ 1, 0.1666, 'solid', theme.color('searchEngine', 'autoCompleteBorder'), ],
  borderRight: [ 1, 0.1666, 'solid', theme.color('searchEngine', 'autoCompleteBorder'), ],
  borderBottom: [ 1, 0.1666, 'solid', theme.color('searchEngine', 'autoCompleteBorder'), ],
});

const buttonMiscStyles = ({ theme, }) => ({
  flexGrow: 0,
  magringInlineStart: 'auto',
  color: theme.color('searchEngine', 'resultsFg'),
});

export default function ArticleSearchBox({
  applicationId,
  apiKey,
  onSuggestionSelected,
  defaultQuery,
}: PropsType) {
  const { theme, } = useFela();

  const suggestionSelectedHandler = (
    event: SyntheticEvent<HTMLElement>,
    data: SuggestionSelected
  ): void => {
    event.preventDefault();
    const query = data.suggestionValue;
    onSuggestionSelected && onSuggestionSelected(query);
  };

  const submitHandler = (event: SyntheticEvent<HTMLElement>, query: ?string) => {
    event.preventDefault();
    query && onSuggestionSelected && onSuggestionSelected(query);
  };

  return (
    <SearchBox
      name="q"
      defaultQuery={defaultQuery}
      indexAppId={applicationId}
      indexSearchApiKey={apiKey}
      inputPlaceholder={theme.headerSearchI18n.placeHolder}
      miscStyles={formMiscStyles({ theme, })}
      inputMiscStyles={inputMiscStyles({ theme, })}
      autocompleteContainerMiscStyles={autocompleteContainerMiscStyles({ theme, })}
      suggestionsContainerMiscStyles={suggestionsContainerMiscStyles({ theme, })}
      buttonMiscStyles={buttonMiscStyles({ theme, })}
      buttonSize={[ { until: 's', value: 4, }, { from: 's', value: 5, }, ]}
      onSuggestionSelected={suggestionSelectedHandler}
      queryMinLength={2}
      indexConfiguration={articlesConfiguration}
      onSubmit={submitHandler}
    />
  );
}
