// @flow
import origonalAlgoliasearch from 'algoliasearch/lite';

type CustomAlgolaSearchOptions = {
  minQueryLength: number,
};

type AlgoliaSearchParams = {
  applicationId: string,
  apiKey: string,
  options?: Object,
  customOptions?: CustomAlgolaSearchOptions,
};

const defaultCustomAlgolaSearchOptions: CustomAlgolaSearchOptions = {
  minQueryLength: 0,
};

function shouldExecuteQuery(query: ?string, minQueryLength: number) {
  let shouldExecute = false;
  if (!query && minQueryLength === 0) {
    shouldExecute = true;
  }
  else if (query && query.length >= minQueryLength) {
    shouldExecute = true;
  }

  return shouldExecute;
}

function algoliasearch({ applicationId, apiKey, options, customOptions, }: AlgoliaSearchParams) {
  const searchClient = origonalAlgoliasearch(applicationId, apiKey, options);
  const userOptions = Object.assign({}, defaultCustomAlgolaSearchOptions, customOptions);
  const { minQueryLength, } = userOptions;

  const customSearchClient = {
    search(requests: Object) {
      if (requests.every(({ params, }) => !shouldExecuteQuery(params.query, minQueryLength))) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        });
      }

      return searchClient.search(requests);
    },
  };

  return customSearchClient;
}

export default algoliasearch;
